<template>
  <div class="page-mobile-example-app">
    <div class="top-bg" :style="`background-image:url(${topBg})`">
      <div class="top-app">
        <img :src="'/mImg/app/top-app.png'|cdn" alt="" >
      </div>
    </div>
    <div class="app-bg" :style="`background-image:url(${bg})`">

      <!--      商城-->
      <div v-for="item in shopContent">
        <div class="line"></div>
        <div class="m-title">{{ item.title }}</div>
        <div class="m-title-line"></div>
        <div class="m-content" v-html="item.content"></div>
        <img :src="item.img" alt="" class="shop-img">
      </div>
      <!--      商会-->
      <div class="m-chamber-title title-main">中国国际商会</div>
      <div class="m-chamber-title title-sub">小程序</div>
      <img :src="'/mImg/app/chamber-phone.png'|cdn" alt="" class="mobile-chamber-phone">
      <div class="mobile-chamber-desc">
        中国国际商会（CCOIC）是1988年经国务院批准成立、由在中国从事国际商事活动的企业、团体和其他组织组成的全国性商会组织，
        是代表中国参与国际商会（ICC）工作的国家商会，在开展与国际商会有关业务时使用国际商会中国国家委员会（ICC
        China）名称，业务主管单位是中国国际贸易促进委员会。
      </div>
      <div class="mobile-bottom-content-wrap">
        <div class="card mobile-card1">
          <img :src="'/mImg/app/icon1.png'|cdn" alt="" class="icon1">
          <span class="card-title m-card1-title">合作方</span>
          <div class="card-content m-card1-content">中国国际商会</div>
        </div>
        <div class="card mobile-card2">
          <img :src="'/mImg/app/icon2.png'|cdn" alt="" class="icon2">
          <span class="card-title m-card2-title">项目功能</span>
          <div class="card-content m-card2-content">活动宣传、项目合作</div>
        </div>
        <div class="card mobile-card3">
          <img :src="'/mImg/app/icon3.png'|cdn" alt="" class="icon3">
          <span class="card-title m-card3-title">技术特色</span>
          <div class="card-content m-card3-content">多页面富文本展示<br/>多种活动关联形式</div>
        </div>
        <div class="m-right-img">
          <img :src="'/mImg/app/calendar.png'|cdn" alt="" class="m-calendar">
          <img :src="'/mImg/app/mine.png'|cdn" alt="" class="m-mine">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "example-app",
  data() {
    return {
      topBg: this.$cdn("/mImg/app/top-bg.png"),
      bg: this.$cdn("/mImg/app/page-bg.png"),
      shopContent: [
        {title: '多分类', content: '亿万商品任你选，一键下单轻松购<br/>精品热门排行榜，天天促销有折扣', img: this.$cdn("/mImg/app/shop-img1.png")},
        {title: '易搜索', content: '精准搜索找商品，地图跟踪查订单<br/>降价通知及时晓，送货时间由你定', img: this.$cdn("/mImg/app/shop-img2.png")},
      ]
    }
  },
  mounted() {
    document.title = `同袍科技-APP/小程序`;
  }
}
</script>

<style scoped lang="scss">
.page-mobile-example-app {
  width: 100vw;
  position: relative;

  .top-bg {
    width: 100vw;
    height: 516px;
    background-size: cover;
    background-position: center;
  }

  .top-app {
     text-align: center;
    position: relative;
    top:314px;
    img{
      width: 708px;
      height: 446px;
    }
  }

  .app-bg {
    width: 100vw;
    height: 3880px;
    background-size: cover;
    background-position: center;
    padding-top: 230px;

    .line {
      width: 654px;
      border-bottom: 2px solid #EDEDED;
      margin: 0 auto;
      margin-top: 94px;
    }

    .m-title {
      font-size: 48px;
      font-weight: 400;
      color: #333333;
      line-height: 66px;
      letter-spacing: 3px;
      text-align: center;
      margin-top: 110px;
    }

    .m-title-line {
      width: 70px;
      height: 4px;
      background: #F8C120;
      margin: 0 auto;
    }

    .m-content {
      font-size: 24px;
      font-weight: 400;
      color: #858585;
      line-height: 34px;
      letter-spacing: 1px;
      margin-top: 20px;
      text-align: center;
    }

    .shop-img {
      width: 316px;
      height: 614px;
      display: block;
      margin: 40px auto 20px auto;
    }

    .m-chamber-title {
      text-align: right;
      font-size: 48px;
      font-weight: 600;
      line-height: 66px;
      padding-right: 86px;
    }

    .title-main {
      margin-top: 260px;
      color: #3D3D3D;
    }

    .title-sub {
      color: #409BFF;
    }

    .mobile-chamber-phone {
      width: 750px;
      height: 398px;
      margin-top: 40px;
    }
    .mobile-chamber-desc{
      width: 646px;
      font-size: 24px;
      font-weight: 400;
      color: #282828;
      line-height: 40px;
      margin: 0 auto;
      margin-top: 30px;
    }
    .mobile-bottom-content-wrap{
      position: relative;
      margin-top: 100px;
      .card{
        width: 240px;
        height: 160px;
        margin-left: 52px;
        margin-bottom: 20px;
        box-shadow: 0px 4px 10px 0px rgba(85, 96, 111, 0.25);
        .card-title{
          font-size: 24px;
          font-weight: 500;
          line-height: 34px;
          vertical-align: middle;
          margin-left: 14px;
        }
        .card-content{
          font-size: 24px;
          font-weight: 400;
          line-height: 34px;
        }
      }
      .mobile-card1{
        background: #ffffff;
        .icon1{
          width: 52px;
          height: 32px;
          margin-top: 34px;
          margin-left: 54px;
        }
        .m-card1-title{
          color: #409BFF;
        }
        .m-card1-content{
          color: #282828;
          padding-left: 48px;
          padding-top: 12px;
        }
      }
      .mobile-card2{
        background: #409BFF;
        .icon2{
          width: 40px;
          height: 48px;
          margin-top: 30px;
          margin-left: 44px;
        }
        .m-card2-title{
          color: #FFFFFF;
        }
        .m-card2-content{
          color: #FFFFFF;
          padding-left: 12px;
          padding-top: 10px;
        }
      }
      .mobile-card3{
        background: #FFFFFF;
        .icon3{
          width: 34px;
          height: 42px;
          margin-top: 20px;
          margin-left: 46px;
        }
        .m-card3-title{
          color: #409BFF;
        }
        .m-card3-content{
          color: #282828;
          padding-left: 24px;
          padding-top: 8px;
        }
      }
      .m-right-img{
        position: absolute;
        top:-5px;
        right:42px ;
        .m-calendar{
          width: 274px;
          height: 542px;
          position: absolute;
          top:0;
          right: 100px;
        }
        .m-mine{
          width: 274px;
          height: 542px;
        }
      }
    }
  }
}
</style>
