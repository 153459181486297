<template>
  <div class="mobile-home">
    <swiper class="m-banner" :options="swiperOption">
      <swiper-slide v-for="(item,index) in banners" :key="index" class="m-swiper-page">
        <img :src="item.url" alt=""/>
      </swiper-slide>
      <div class="swiper-pagination"  slot="pagination"></div>
    </swiper>
    <img :src="'/mImg/home/title-1.png'|cdn" alt="" class="title-1">
    <div class="icons-show">
      <div class="icon-bg">
        <img :src="'/mImg/home/icon-1.png'|cdn" alt="">
        <div class="icon-title">技术丰富</div>
        <div class="icon-en">Rich Technology</div>
      </div>
      <div class="icon-bg">
        <img :src="'/mImg/home/icon-2.png'|cdn" alt="">
        <div class="icon-title">专人负责</div>
        <div class="icon-en">Special Person In Charge</div>
      </div>
    </div>
    <div class="icon3-bg">
      <img :src="'/mImg/home/icon-3.png'|cdn" alt="">
      <div class="right-content">
        <div class="icon-title">软硬一体</div>
        <div class="icon-en">Integration Of<br/>
          Software And Hardware</div>
      </div>
    </div>
    <img :src="'/mImg/home/title-2.png'|cdn" alt="" class="title-2">
   <div class="case-show">
     <div class="case-wrap" @click="toDirectPage(item.routeName)" v-for="item in cases">
       <div :style="`background-image:url(${item.url})`" class="case-img"></div>
       <div class="case-title">{{item.title}}</div>
       <div class="case-en">{{item.en}}</div>
       <img :src="'/mImg/home/case-arrow.png'|cdn" alt="" class="arrow">
     </div>
   </div>
  </div>
</template>
<script>
export default {
  name:"mobile-home",
  mounted() {
    document.title = `同袍科技-首页`;
  },
  data(){
    return{
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: true,
        autoplay: true
      },
      banners:[
        {url:this.$cdn("/mImg/home/banner1.png")},
        {url:this.$cdn("/mImg/home/banner2.png")},
        {url:this.$cdn("/mImg/home/banner3.png")},
        {url:this.$cdn("/mImg/home/banner4.png")},
      ],
      cases:[
        {url:this.$cdn("/mImg/home/case1.png"),title:'全景直播',en:'Panoramic live broadcast',routeName:'mobile-example-pi'},
        {url:this.$cdn("/mImg/home/case2.png"),title: '官网开发',en:'Official website',routeName: 'mobile-example-index'},
        {url:this.$cdn("/mImg/home/case3.png"),title: '联合国VR城市',en:'United Nations VR city',routeName: 'mobile-example-vr-city'},
        {url:this.$cdn("/mImg/home/case4.png"),title: 'APP/小程序',en:'APP/Mini Program',routeName:'mobile-example-app'},
        {url:this.$cdn("/mImg/home/case5.png"),title: 'H5',en:'H5',routeName: 'mobile-example-h5'},
      ]
    }
  },
  methods:{
    toDirectPage(name){
      this.$router.push({name:name})
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile-home{
  width:100vw;
  .m-banner{
   display: block;
  }
  .swiper-pagination-bullet{
    width: 4px;
    height: 4px;
    background: #A9A9A9;
    z-index: 99;
  }
  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 60px;
  }
  .swiper-pagination-bullet-active .swiper-pagination-bullet-active .swiper-pagination-bullet-active{
    width: 18px;
    height: 4px;
    background: #FFFFFF!important;
    box-shadow: 0px 1px 1px 0px rgba(159, 159, 159, 0.5);
    border-radius: 8px;
  }
  .m-swiper-page{
    width: 100vw;
    img{
      width: inherit;
    }
  }
  .title-1{
    width: 420px;
    height: 68px;
    display: block;
    margin: 0 auto;
    margin-top: 80px;
  }
  .icon-title{
    font-size: 24px;
    font-weight: 600;
    color: #2C2C2C;
    line-height: 34px;
  }
  .icon-en{
    font-size: 20px;
    font-weight: 500;
    color: #A4A4A4;
    line-height: 28px;
  }
  .icons-show{
    width: calc(100vw - 120px);
    padding-left: 60px;
    display: flex;
    justify-content: space-between;
    margin-top: 44px;
    .icon-bg{
      width: 47%;
      height: 220px;
      background: #F8F8F8;
      position: relative;
      text-align: center;
      img{
        width: 88px;
        height: 100px;
        margin-top: 26px;
      }
    }
  }
  .icon3-bg{
    width: calc(100vw - 120px);
    height: 220px;
    background: #F8F8F8;
    margin-top: 38px;
    margin-left: 60px;
    position: relative;
    img{
      width: 88px;
      height: 100px;
      margin-top: 62px;
      margin-left: 142px;
    }
    .right-content{
      position: absolute;
      top:66px;
      left: 258px;
      text-align: center;
    }
  }
  .title-2{
    width: 420px;
    height: 68px;
    display: block;
    margin: 80px auto 44px auto;
  }
  .case-show{
    padding-bottom: 54px;
  }
  .case-wrap{
    width: calc(100vw - 120px);
    height: 400px;
    background: #F8F8F8;
    box-shadow: 0px 4px 8px 0px rgba(198, 198, 198, 0.5);
    margin: 0 auto 30px auto;
    position: relative;
    .case-img{
      width: calc(100% - 50px);
      height: 270px;
      display: inline-block;
      margin-top: 20px;
      margin-left: 26px;
      background-size: cover;
      background-position: center;
    }
    .case-title{
      font-size: 28px;
      font-weight: 600;
      color: #2C2C2C;
      line-height: 40px;
      letter-spacing: 1px;
      margin-left: 26px;
      margin-top: 20px;
    }
    .case-en{
      font-size: 20px;
      font-weight: 400;
      color: #A3A3A3;
      line-height: 28px;
      letter-spacing: 1px;
      margin-left: 26px;

    }
    .arrow{
      width: 42px;
      height: 14px;
      position: absolute;
      right: 34px;
      bottom: 48px;

    }
  }
}
</style>
