<template>
  <div class="mobile-example-h5">
    <div class="m-bg1" :style="`background-image:url(${bg1})`">
      <img :src="'/mImg/h5/bg1-phone-left.png'|cdn" alt="" class="m-left-phone">
      <img :src="'/mImg/h5/bg1-phone-right.png'|cdn" alt="" class="m-right-phone">
    </div>
    <div class="m-h5-content1">
      <div class="h5-content-title">雨中城 —— 品牌H5</div>
      <div class="h5-content-desc">如果有一座永远下雨的城市，会是什么样？
        我们协助新世相和百度共同创建了一座虚拟的“一直下雨的城市”，在“雨声博物馆”中，你可以置身于虚拟全景中，欣赏来自世界各地的雨声和故事。</div>
      <div class="h5-content-desc">合作方：新世相 X 百度<br/>项目功能：品牌宣传<br/>技术特色：多种陀螺仪交互，高性能 3D 全景场景交互。</div>
      <div class="m-h5-qrcode">
        <img :src="'/mImg/h5/qrcode1.png'|cdn" alt="">
        <div class="h5-qrcode-tips">扫码体验</div>
      </div>
    </div>
    <div class="m-bg2" :style="`background-image:url(${bg2})`">
      <img :src="'/mImg/h5/bg2-phone-left.png'|cdn" alt="" class="m-bg2-left-phone">
      <img :src="'/mImg/h5/bg2-phone-right.png'|cdn" alt="" class="m-bg2-right-phone">
    </div>
    <div class="m-h5-content2">
      <div class="h5-content-title">自如寓邀你打call —— 品牌H5</div>
      <div class="h5-content-desc">以 H5 的形式在微信体系中传播推广自如新推出的公寓。</div>
      <div class="m-h5-qrcode">
        <img :src="'/mImg/h5/qrcode2.png'|cdn" alt="">
        <div class="h5-qrcode-tips">扫码体验</div>
      </div>
    </div>
    <div class="m-bg3" :style="`background-image:url(${bg3})`">
      <img :src="'/mImg/h5/bg3-phone-left.png'|cdn" alt="" class="m-bg3-left-phone">
      <img :src="'/mImg/h5/bg3-phone-right.png'|cdn" alt="" class="m-bg3-right-phone">
    </div>
    <div class="m-h5-content3">
      <div class="h5-content-title">H5 游戏 —— 游戏开发</div>
      <div class="h5-content-desc">一款基于网络热梗的投掷类H5小游戏。可实现排行榜、微信分享、海报生成等营销功能。</div>
      <div class="h5-content-desc">项目功能：品牌推广<br/>技术特点：快速开发迭代，丰富的交互动效和手感，自研基于 DOM 和 MVVM 的游戏碰撞检测。</div>
      <div class="m-h5-qrcode">
        <img :src="'/mImg/h5/qrcode3.png'|cdn" alt="">
        <div class="h5-qrcode-tips">扫码体验</div>
      </div>
    </div>
    <div class="m-bg4" :style="`background-image:url(${bg4})`">
      <img :src="'/mImg/h5/ipad.png'|cdn" alt="">
    </div>
    <div class="m-h5-content4">
      <div class="h5-content-title">英语教学游戏 —— 游戏开发</div>
      <div class="h5-content-desc">易魔豆——为教育机构、培训学校、幼儿园等提供金球优质教育资源和教育技术支持的服务平台；提供针对课程体系的教学、教研、培训、测评等。</div>
      <div class="h5-content-desc">合作方：贝果英语<br/>技术特点：可以打包构建到web，h5，ios，android，微信小游戏等各端平台运行，易于使用、高效、灵活。</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "example-h5",
  data(){
    return{
      bg1:this.$cdn("/mImg/h5/bg1.png"),
      bg2:this.$cdn("/mImg/h5/bg2.png"),
      bg3:this.$cdn("/mImg/h5/bg3.png"),
      bg4:this.$cdn("/mImg/h5/bg4.png"),
    }
  },
  mounted() {
    document.title = `同袍科技-移动H5`;
  }
}
</script>

<style scoped lang="scss">
@mixin bgImg {
  width: 100vw;
  height: 610px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.mobile-example-h5 {
  width: 100vw;
  font-family: "siyuan", sans-serif;
  .m-bg1{
    @include bgImg;
    .m-left-phone{
      width: 256px;
      height: 524px;
      margin-top: 40px;
      margin-left: 96px;
    }
    .m-right-phone{
      width: 260px;
      height: 528px;
      margin-left: 40px;
    }
  }
  .m-bg2{
    @include bgImg;
    .m-bg2-left-phone{
      width: 256px;
      height: 508px;
      margin-top: 50px;
      margin-left: 96px;
    }
    .m-bg2-right-phone{
      width: 256px;
      height: 508px;
      margin-left: 40px;
    }
  }
  .m-bg3{
    @include bgImg;
    .m-bg3-left-phone{
      width: 256px;
      height: 516px;
      margin-top: 44px;
      margin-left: 96px;
    }
    .m-bg3-right-phone{
      width: 256px;
      height: 516px;
      margin-left: 40px;
    }
  }
  .m-bg4{
    @include bgImg;
    img{
      width: 622px;
      height: 440px;
      margin-top: 86px;
      margin-left: 70px;
    }
  }
  .h5-content-title{
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    line-height: 66px;
    padding-top: 46px;
    padding-left: 42px;
  }
  .h5-content-desc{
    width: calc(100vw - 84px);
    font-size: 24px;
    font-weight: 400;
    color: #5C5C5C;
    line-height: 40px;
    padding-top: 20px;
    padding-left: 42px;
  }
  .m-h5-qrcode{
    margin-top: 20px;
    display: inline-block;
    margin-left: 42px;
    img{
      width: 140px;
      height: 140px;
      -webkit-touch-callout: default;
    }
    .h5-qrcode-tips{
      font-size: 20px;
      font-weight: 400;
      color: #363636;
      line-height: 32px;
      text-align: center;
    }
  }
  .m-h5-content1,.m-h5-content2,.m-h5-content3{
    padding-bottom: 66px;
  }
  .m-h5-content4{
    padding-bottom: 42px;
  }
}
</style>
