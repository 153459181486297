<template>
  <div class="mobile-example-index">
    <div class="m-index-top" :style="`background-image:url(${topBg})`">
      <img :src="'/mImg/index/top-large-phone.png'|cdn" alt="" class="ipad">
      <img :src="'/mImg/index/top-small-phone.png'|cdn" alt="" class="top-phone">
    </div>
    <div class="m-note-content-wrap">
      <div class="m-title-text m-note-title">格子笔记 —— 官网</div>
      <div class="m-content-text m-note-content">合作方：格子笔记<br/>项目功能：产品展示<br/>技术特点：静态页面</div>
      <div class="right-note-wrap">
        <img :src="'/mImg/index/top-qrcode.png'|cdn" alt="" class="note-qrcode">
        <div class="right-text">移动端扫码体验</div>
        <div class="right-text">PC端网址：http://note.sumi.io</div>
      </div>
    </div>
    <div :style="`background-image:url(${bottomBg})`" class="m-index-bottom-bg">
      <img :src="'/mImg/index/bottom-phone.png'|cdn" alt="" class="m-bottom-phone">
      <div class="m-web-site">PC端网址：https://nuobaiai.tpstu.com/#/</div>
    </div>
    <div class="m-bottom-content">
      <div class="m-title-text bottom-title">诺百爱（杭州）科技 —— 官网</div>
      <div class="m-content-text m-bottom-desc">
        诺百爱（杭州）科技有限责任公司是国内首创的生物电信号领域人工智能头部企业，提供生物电信号的AI解决方案，
        包括肌电、脑电、心电等识别及应用。核心产品包括国内第一款手语翻译臂环、新型的肌电交互臂环，可作为AR/VR的“鼠标键盘”等，
        还研发了首款帮助聋人接打电话的辅助通话设备“龙语”；成立一年多，已经承担军委科技委、装备发展部等单位重要合作项目。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "example-index",
  data() {
    return {
      topBg: this.$cdn("/mImg/index/top-bg.png"),
      bottomBg: this.$cdn("/mImg/index/bottom-bg.png"),
    }
  },
  mounted() {
    document.title = `同袍科技-官网开发`;
  }
}
</script>

<style scoped lang="scss">
.mobile-example-index {
  width: 100vw;
  .m-index-top {
    width: 100vw;
    height: 480px;
    background-size: cover;
    background-position: center;
    position: relative;

    .ipad {
      width: 528px;
      height: 366px;
      margin-top: 60px;
      margin-left: 90px;
    }

    .top-phone {
      width: 154px;
      height: 306px;
      position: absolute;
      bottom: 24px;
      right: 88px;
    }
  }

  .m-note-content-wrap {
    width: 750px;
    height: 320px;
    position: relative;

    .m-note-title {
      padding-top: 48px;
    }

    .m-note-content {
      padding-top: 20px;
    }

    .right-note-wrap {
      position: absolute;
      top: 46px;
      right: 60px;
      text-align: right;

      .note-qrcode {
        width: 140px;
        height: 140px;
        -webkit-touch-callout: default;
      }

      .right-text {
        font-size: 20px;
        font-weight: 400;
        color: #363636;
        line-height: 32px;
      }
    }
  }

  .m-title-text {
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    line-height: 66px;
    padding-left: 40px;
    font-family: "siyuanBold", sans-serif;
  }

  .m-content-text {
    font-size: 24px;
    font-weight: 400;
    color: #5C5C5C;
    line-height: 40px;
    padding-left: 40px;
  }

  .m-index-bottom-bg {
    width: 100vw;
    height: 480px;
    background-position: center;
    background-size: cover;
    position: relative;

    .m-bottom-phone {
      width: 528px;
      height: 366px;
      margin-top: 46px;
      margin-left: 112px;
    }

    .m-web-site {
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 32px;
      position: absolute;
      bottom: 18px;
      right: 54px;
    }
  }

  .m-bottom-content {
    width: 100vw;
    height: 452px;

    .bottom-title {
      padding-top: 46px;
    }
    .m-bottom-desc{
      width: 656px;
      margin-top: 20px;
    }
  }
}
</style>
