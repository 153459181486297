<template>
  <div class="page-mobile-vr-city">
    <img :src="'/mImg/vr-city/img.png'|cdn" alt="">
  </div>
</template>

<script>
export default {
  name: "example-vr-city",
  mounted() {
    document.title = `同袍科技-联合国VR城市`;
  }
}
</script>

<style scoped lang="scss">
.page-mobile-vr-city {
  width: 100vw;

  img {
    width: 100vw;
    margin-bottom: -14px;
  }
}
</style>
