<template>
  <div id="app">
    <router-view/>
    <m-footer/>
  </div>
</template>
<script>

import MFooter from "@/mobile/components/m-footer";
export default {
  components: {MFooter},
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "siyuanRegular";
  src: url("/static/SourceHanSansCN-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "siyuanBold";
  src: url("/static/SourceHanSansCN-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
body,
html {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transform-origin: left top;
}

#app {
  width: 100vw;
  overflow-x: hidden;
  font-family: "siyuanRegular", sans-serif;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-clip: padding-box;
}

::-webkit-scrollbar,
::-webkit-scrollbar-button {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(38, 39, 40, 0.51);
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
</style>
