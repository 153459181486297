import 'reset-css/reset.css';
// import 'swiper/swiper-bundle.css';
import 'swiper/dist/css/swiper.min.css';
import Vue from 'vue';

import VueAwesomeSwiper from 'vue-awesome-swiper';

import App from './App.vue';
import router from './router';
import { resolvePath } from '@/utils';


Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);

Vue.prototype.$cdn = resolvePath;

Vue.filter("cdn", function(path) {
    return resolvePath(path);
});

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
