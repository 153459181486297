<template>
  <div class="mobile-footer">
    <img :src="'/mImg/footer/footer-bg.png'|cdn" alt="">
<!--    <div class="m-footer-content-wrap">-->
<!--      <div class="m-logo-name">-->
<!--        <img :src="'/mImg/footer/logo.png'|cdn" alt="">-->
<!--        <span class="m-footer-text">同袍科技</span>-->
<!--      </div>-->
<!--      <div class="m-contact m-footer-text">联系方式：</div>-->
<!--      <div class="m-footer-text m-contact-ways">邮箱：contact@tongpaostudio.com</div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "m-footer",
  data(){
    return{
      bg:this.$cdn("/mImg/footer/footer-bg.png")
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-footer {
  width: 100vw;
  height: 136px;

  img{
    width: 100vw;
    height: 136px;
    -webkit-touch-callout: default;
  }
}
</style>
