<template>
  <div class="page-mobile-example-pi">
    <div class="m-pi-banner" :style="`background-image:url(${banner})`"></div>
    <div class="m-desc">跨平台、多终端、高实时性、全景音视频5G沟通解决方案</div>
    <div class="center-content">
      <div class="center-content-wrap" v-for="(item,index) in content">
        <img :src="item.icon" alt="" :class="`pi-icon-${index}`">
        <div class="mini-line"></div>
        <div v-html="item.desc" class="desc"></div>
        <div class="number" :class="`number-${index}`">0{{ index + 1 }}</div>
      </div>
      <div class="last-content">
        <img :src="'/mImg/pi/icon5.png'|cdn" alt="">
        <div class="last-content-desc">软硬件一体化解决方案<br/>支持5G/8K</div>
        <div class="last-number">05</div>
      </div>
    </div>
    <!--    应用场景-->
    <img :src="'/mImg/pi/title1.png'|cdn" alt="" class="pi-title1">
    <div class="m-swiper">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item,i) in imgs" :key="i"  class="m-swiper-slide">
          <img :src="item.url" alt=""/>
          <div class="m-img-title">{{item.title}}</div>
        </swiper-slide>
      </swiper>
      <img :src="'/mImg/pi/left-arrow.png'|cdn" alt="" class="swiper-button-prev">
      <img :src="'/mImg/pi/right-arrow.png'|cdn" alt="" class="swiper-button-next">
    </div>
    <!--    服务对象-->
    <img :src="'/mImg/pi/title2.png'|cdn" alt="" class="pi-title2">
    <div class="service-wrap">
      <img :src="'/mImg/pi/service1.png'|cdn" alt="" class="service1">
      <img :src="'/mImg/pi/service2.png'|cdn" alt="" class="service2">
      <img :src="'/mImg/pi/service3.png'|cdn" alt="" class="service3">
    </div>
  </div>
</template>

<script>
export default {
  name: "example-pi",
  mounted() {
    document.title = `同袍科技-全景直播`;
  },
  data() {
    return {
      banner: this.$cdn("/mImg/pi/banner.png"),
      imgs: [
        {url: this.$cdn("/mImg/pi/img1.png"),title:'园区安保 路况查看'},
        {url: this.$cdn("/mImg/pi/img2.png"),title: '工地踏勘 现场验收'},
        {url: this.$cdn("/mImg/pi/img3.png"),title: '林场 河道巡逻'},
      ],
      content: [
        {icon: this.$cdn("/mImg/pi/icon1.png"), desc: '全景相机直播 支持VR<br/>实时回传现场画面'},
        {icon: this.$cdn("/mImg/pi/icon2.png"), desc: 'PC端/手机端全景播放<br/>无死角拖动查看'},
        {icon: this.$cdn("/mImg/pi/icon3.png"), desc: '多端即时通讯<br/>音视频会议远程指挥'},
        {icon: this.$cdn("/mImg/pi/icon4.png"), desc: '后台管理模块<br/>实时掌握项目进展、<br/>设备状况'},
      ],
      swiperOption: {
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        direction: 'horizontal',
        loop: true,
        height: 592 * window.document.body.offsetWidth/750,
        // autoplay: true,
        initialSlide: 1,
        slidesPerView: 750/442,
        // slidesPerView: 'auto',
        spaceBetween: -8,
        centeredSlides: true,
        loopAdditionalSlides: 100,
        navigation: { // 左右按钮
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // effect: 'coverflow',
        // coverflowEffect: {
        //   rotate: 40,
        //   stretch: 0,
        //   depth: 50,
        //   modifier: 1,
        //   slideShadows: true,
        // }
      }
    }
  }
}
</script>

<style scoped lang="scss">
$size :0.7837837837837838;
.page-mobile-example-pi {
  width: 100vw;
  font-family: "siyuan", sans-serif;
  .m-pi-banner {
    width: 100vw;
    height: 326px;
    background-position: center;
    background-size: cover;
  }

  .m-desc {
    font-size: 24px;
    font-weight: 400;
    color: #272727;
    line-height: 34px;
    text-align: center;
    margin-top: 24px;
  }

  .center-content {
    width: calc(100vw - 120px);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .center-content-wrap {
      width: 48%;
      height: 280px;
      background: #F8F8F8;
      position: relative;
      margin-top: 24px;
      overflow: hidden;

      .desc {
        font-size: 24px;
        font-weight: 400;
        color: #272727;
        line-height: 40px;
        text-align: center;
      }

      .number {
        font-size: 72px;
        font-weight: 600;
        color: #E4E4E4;
        line-height: 100px;
        text-align: center;
        margin-top: 20px;
      }

      .number-3 {
        margin-top: -20px;
      }

      .mini-line {
        width: 20px;
        height: 2px;
        background: #3A5C8E;
        margin: 18px auto 10px auto;
      }

      .pi-icon-0 {
        width: 60px;
        height: 58px;
        display: block;
        margin: 42px auto 18px auto;
      }

      .pi-icon-1 {
        width: 62px;
        height: 56px;
        display: block;
        margin: 42px auto 18px auto;
      }

      .pi-icon-2 {
        width: 56px;
        height: 56px;
        display: block;
        margin: 42px auto 18px auto;
      }

      .pi-icon-3 {
        width: 56px;
        height: 56px;
        display: block;
        margin: 42px auto 18px auto;
      }
    }

    .last-content {
      width:  calc(100vw - 120px);
      height: 180px;
      background: #F8F8F8;
      margin-top: 24px;
      position: relative;
      overflow: hidden;

      img {
        width: 60px;
        height: 80px;
        margin-top: 46px;
        margin-left: 138px;
      }

      .last-content-desc {
        position: absolute;
        top: 48px;
        left: 248px;
        font-size: 24px;
        font-weight: 400;
        color: #272727;
        line-height: 40px;
        text-align: center;
      }

      .last-number {
        font-size: 72px;
        font-weight: 600;
        color: #E4E4E4;
        line-height: 100px;
        text-align: center;
        margin-top: -10px;
      }
    }
  }

  .pi-title1 {
    width: 420px;
    height: 70px;
    display: block;
    margin: 0 auto;
    margin-top: 80px;
  }


  .swiper-slide-active {
    width: 442px !important;
    height: 670px;
  }
  .swiper-slide-prev{
    width: 326px;
    transform: scale($size);
  }
  .swiper-slide-next{
    width: 326px;
    transform: scale($size);
  }
  //.swiper-slide-prev {
  //  transform-origin: center;
  //}
  //.swiper-button-next {
  //  transform-origin: center;
  //}
  .swiper-slide-prev:before,.swiper-slide-next:before{
    content: '';
    display: block;
    background: rgba(#fff,0.5);
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left:0;
  }
  .m-swiper {
    width: 100vw;
    height: 650px;
    overflow: visible !important;
    margin-top: 42px;
    position: relative;


    .swiper-button-prev {
      width: 76px;
      height: 76px;
      z-index: 99;
    }

    .swiper-button-next {
      width: 76px;
      height: 76px;
    }

    .m-swiper-slide {
      width: 326px;
      transition: all ease 0.3s;
      img {
        width: 100%;
      }
      .m-img-title{
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #272727;
        line-height: 32px;
        font-family: "siyuan", sans-serif;
        margin-top: 20px;
      }
    }
  }

  .pi-title2 {
    width: 420px;
    height: 68px;
    display: block;
    margin: 0 auto;
    margin-top: 80px;
  }

  .service-wrap {
    width: calc(100vw - 72px - 76px);
    margin: 22px auto 36px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .service1 {
      width: 266px;
      height: 70px;
    }

    .service2 {
      width: 266px;
      height: 112px;
    }

    .service3 {
      width: 268px;
      height: 136px;
      margin-top: 22px;
    }
  }
}
</style>
