import Vue from 'vue';

import VueRouter from 'vue-router';

import Home from '../pages/home';
import ExampleApp from "../pages/example-app"
import ExampleVrCity from "../pages/example-vr-city"
import ExamplePI from  "../pages/example-pi"
import ExampleIndex from "../pages/example-index"
import ExampleH5 from  "../pages/example-h5"
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            nav: "home",
        },
    },
    {
        path: "/mobile/example-app",
        name: "mobile-example-app",
        component: ExampleApp
    },
    {
        path: "/mobile/example-vr-city",
        name: "mobile-example-vr-city",
        component: ExampleVrCity
    },
    {
        path: "/mobile/example-pi",
        name: "mobile-example-pi",
        component: ExamplePI
    },
    {
        path: "/mobile/example-index",
        name: "mobile-example-index",
        component: ExampleIndex
    },
    {
        path: "/mobile/example-h5",
        name: "mobile-example-h5",
        component: ExampleH5
    }
];

const router = new VueRouter({
    routes,
});

export default router;
